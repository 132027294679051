import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import lessonsReducer from "redux-toolkit/lessons/slice";
import coursesReducer from "redux-toolkit/courses/slice";

const combinedReducer = combineReducers({
  lessons: lessonsReducer,
  courses: coursesReducer,
});

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
