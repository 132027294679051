import React from "react";
import Link from "next/link";
import Image from "next/image";
import logo from "../../public/logo_1.svg";
// import dpranoc from "../../public/dpranoc.svg";

const firstList = [
  {
    title: "Մենք",
  },
  {
    title: "Մեր մասին",
    value: "/about-us",
  },
  { title: "Կապ մեզ հետ", value: "/contact-us" },
  { title: "Առաքելություն", value: "/mission" },
  { title: "Ինչպես ենք աշխատում", value: "/our-work" },
];

const secondList = [
  {
    title: "Օգնություն",
  },
  { title: "Հաճախ տրվող հարցեր", value: "/faq" },
  { title: "Կառավարում և աջակցություն", value: "/support" },
];

const thirdList = [
  {
    title: "Օրենսդրություն",
  },
  { title: "Պայմաններ և դրույթներ", value: "/therms-and-conditions" },
  { title: "Գաղտնիության քաղաքականություն", value: "/privacy-and-policy" },
];

const Footer = () => {
  return (
    <div className="flex md:flex-row md-gap-0 gap-4 flex-col justify-between items-start bg-white rounded-[24px] xl:mx-24 my-10 px-10 py-6 border">
      <Link href="/" className="flex gap-4 justify-start cursor-pointer">
        <Image
          width={42}
          height={58}
          sizes="100vh"
          src={logo}
          alt="logo"
          priority
        />
        <span className="text-[40px] font-bold">Միտք</span>
      </Link>
      <div className="flex flex-col">
        {firstList.map((page, index) => (
          <Link
            key={page.value || index}
            className={`bg-white px-4 text-[16px] ${!page?.value && "text-[#493ED3] text-[20px] font-bold"} py-2 rounded-md ${page?.value && "font-medium cursor-pointer hover:bg-[#493ED3] hover:text-white transition-all duration-500"}`}
            href={page.value || ""}
          >
            {page.title}
          </Link>
        ))}
      </div>
      <div className="flex flex-col">
        {secondList.map((page, index) => (
          <Link
            key={page.value || index}
            href={page.value || ""}
            className={`bg-white px-4 text-[16px] ${!page?.value && "text-[#493ED3] text-[20px] font-bold"} py-2 rounded-md ${page?.value && "font-medium cursor-pointer hover:bg-[#493ED3] hover:text-white transition-all duration-500"}`}
          >
            {page.title}
          </Link>
        ))}
      </div>
      <div className="flex flex-col">
        {thirdList.map((page, index) => (
          <Link
            key={page.value || index}
            href={page.value || ""}
            className={`bg-white px-4 text-[16px] ${!page?.value && "text-[#493ED3] text-[20px] font-bold"} py-2 rounded-md ${page?.value && "font-medium cursor-pointer hover:bg-[#493ED3] hover:text-white transition-all duration-500"}`}
          >
            {page.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Footer;
