import * as React from "react";
import Link from "next/link";
import Image from "next/image";
import { IoMenu, IoClose } from "react-icons/io5"; // Icons for menu toggle
import { useRouter } from "next/router"; // Import useRouter hook
import logo from "../../public/logo_1.svg";
// import dpranoc from "../../public/dpranoc.svg";

const pages = [
  { title: "Ազգային կրթակարգ", value: "/national-curriculum" },
  { title: "Հայեցակարգեր", value: "/concepts-overview" },
  { title: "Առարկայացանկ", value: "/subjects-list" },
  { title: "Դասընթացներ", value: "/courses" },
];

function ResponsiveAppBar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const router = useRouter(); // Access the router

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const getLinkClass = (pageValue) => {
    return router.pathname.includes(pageValue)
      ? "bg-[#493ED3] text-white px-4 py-2 rounded-md cursor-pointer transition-all duration-500"
      : "bg-white px-4 py-2 rounded-md cursor-pointer hover:bg-[#493ED3] hover:text-white transition-all duration-500";
  };

  return (
    <div className="relative">
      {/* Top bar */}
      <div className="flex justify-between items-center bg-white rounded-[24px] xl:mx-24 mt-10 px-10 py-6 border">
        <Link href="/" className="flex gap-4 items-center cursor-pointer">
          <Image
            width={42}
            height={58}
            sizes="100vh"
            src={logo}
            alt="logo"
            priority
          />
          <span className="text-[40px] font-bold">Միտք</span>
        </Link>

        {/* Desktop menu */}
        <div className="hidden xl:flex gap-4">
          {pages.map((page) => (
            <Link
              key={page.value}
              className={getLinkClass(page.value)} // Apply the active class conditionally
              href={page.value}
            >
              {page.title}
            </Link>
          ))}
        </div>

        {/* Mobile menu toggle button */}
        <button
          onClick={toggleMobileMenu}
          className="xl:hidden text-2xl p-2 focus:outline-none"
        >
          {isMobileMenuOpen ? <IoClose /> : <IoMenu />}
        </button>
      </div>

      {/* Mobile menu with animation */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center transition-transform duration-300 ${
          isMobileMenuOpen
            ? "translate-x-0 opacity-100"
            : "translate-x-full opacity-0"
        }`}
      >
        <div className="bg-white w-full h-full p-6 flex flex-col items-center gap-6">
          {/* Close button */}
          <button
            onClick={toggleMobileMenu}
            className="text-2xl self-end p-2 bg-gray-200 hover:bg-gray-300 rounded-full focus:outline-none"
          >
            <IoClose />
          </button>

          {pages.map((page) => (
            <Link
              key={page.value}
              className={`text-xl text-center w-full py-3 rounded-md ${
                router.pathname === page.value
                  ? "bg-[#493ED3] text-white"
                  : "bg-gray-200 hover:bg-[#493ED3] hover:text-white"
              } transition-all duration-300`}
              href={page.value}
              onClick={toggleMobileMenu}
            >
              {page.title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ResponsiveAppBar;
