import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBGQciRzvpIrsA2BKNtFRzxPrgc8VRxHPM",
  authDomain: "armtrain-cd7c6.firebaseapp.com",
  databaseURL: "https://armtrain-cd7c6-default-rtdb.firebaseio.com",
  projectId: "armtrain-cd7c6",
  storageBucket: "armtrain-cd7c6.appspot.com",
  messagingSenderId: "416418785597",
  appId: "1:416418785597:web:4381d232667012f08b3d54",
  measurementId: "G-9MH4N194CJ",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
export const analytics =
  typeof window !== "undefined" ? getAnalytics(app) : null;
