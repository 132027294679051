import { Provider } from "react-redux";
import Head from "next/head";
import ResponsiveAppBar from "components/appbar/ResponsiveAppBar";
import Footer from "components/footer/Footer";
import { store } from "redux-toolkit/store";
import ThemeProvider from "../theme";
import "styles/globals.css";

export default function App({ Component, pageProps }) {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <Head>
          <title>Միտք</title>
          {/* Meta Tags for SEO */}
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          {/* Favicon */}
          <link rel="icon" href="/logo_1.svg" />
          <meta property="og:type" content="website" />
          <meta
            name="keywords"
            content="Միտք, dproc, mitq, mitk, դպրոց, դասեր, ուսում, կրթություն, ազգային դպրոց, ազգային կրթակարգ, հայեցակարգեր, առարկայացանկ, դասընթացներ"
          />
        </Head>
        <ResponsiveAppBar />
        <Component {...pageProps} />
        <Footer />
      </ThemeProvider>
    </Provider>
  );
}
